import { v4 } from 'uuid'
import api from '../../api'
import i18n from '../../i18n'
import { addNotification } from '../popup/popup-actions'
import {
  getDoctorsBySearchSuccess,
  getDoctorsBySearchRequest,
  getRandomDocSuccess,
  getPeriodsSuccess,
  changeType,
  checkIfEmpty,
  pageSearchRequest,
  getBySearchSuccess,
  setIsSearchEmpty,
  getPeriodsRequest,
} from './doctors-actions'
// filter[specialty]=38&filter[fullName]=&filter[type]=0

export const getDoctorsListBySearch = (params) => async dispatch => {
  let filterString = ''
  if (typeof params === 'object' && Object.keys(params).length > 0) {
    // filterString = `filter[specialty]=${params.id}&filter[type]=${params.type}`
    filterString = Object.keys(params).reduce((acc, key) => {
      acc.push(`filter[${key}]=${params[key]}`)
      return acc
    }, []).join('&')
  }
  // let filterString = `filter[specialty]=${params.id}&filter[type]=${params.type}&filter[fullName]=${params.query}`
  // // dispatch(getDoctorsBySearchRequest)
  try {
    const data = await api.doctors.getDoctorsBySearch(filterString)
    dispatch(getDoctorsBySearchSuccess(data))
  } catch(error) {
    console.log(error);
  }
}

export const changeConsultationType = () => dispatch => {
  dispatch(changeType())
}

export const getDoctors = (params, page = 1) => async dispatch => {
  dispatch(getDoctorsBySearchRequest());

  let filterString = '';
  if (typeof params === 'object' && Object.keys(params).length > 0) {
    filterString = Object.keys(params).reduce((acc, key) => {
      acc.push(`filter[${key}]=${params[key]}`);
      return acc;
    }, []).join('&');
  }

  const timestamp = new Date().getTime();
  filterString += `&timestamp=${timestamp}`;

  console.log(filterString);

  try {
    const data = await api.doctors.getDoctorsBySearch(filterString, page);
    dispatch(getDoctorsBySearchSuccess(data));
    dispatch(checkIfEmpty(data));
  } catch (error) {
    console.log(error);
  }
};

export const getDoctorsBySearch = (query, page = 1) => async dispatch => {
  dispatch(pageSearchRequest())
  try {
    const data = await api.doctors.getBySearch(query, page)
    if (data.data.items.length === 0) {
      dispatch(setIsSearchEmpty(true))
    } else {
      dispatch(setIsSearchEmpty(false))
    }
    dispatch(getBySearchSuccess(data))
  } catch (e) {
    console.log(e.message);
  }
}

export const getRandomDoctor = (specialtyId, type = '', hospitalId = null, districtId = null) => async dispatch => {
  dispatch(getPeriodsRequest())
  try {
    const data = await api.doctors.getRandomDoctor(specialtyId, type, hospitalId, districtId)
    // console.log(data);
    if (!data?.entity?.doctor) {
      dispatch(addNotification({
        id: v4(),
        type: "ERROR",
        message: i18n.t("noDoctors")
      }))
      return
    }
    dispatch(getRandomDocSuccess(data))
    dispatch(getPeriodsSuccess(data))
  } catch (e) {
    console.log(e);
  }
}

export const getDoctorPeriods = (doctorId, specialtyId, type, hospitalId = null, districtId) => async dispatch => {
  dispatch(getPeriodsRequest())
  try {
    const data = await api.doctors.getDoctorPeriods(doctorId, specialtyId, type, hospitalId, districtId)
    dispatch(getPeriodsSuccess(data))
  } catch (e) {
    console.log(e);
  }
}