import React from 'react'
import {routers} from '../config'
import { Route, Routes, Navigate } from 'react-router'
import ChatModeObserver from '../chatHoc/ChatModeObserver';
const Clinic = React.lazy(() => import('../views/pages/Clinic/Clinic'));
const ConsultationBooking = React.lazy(() => import('../views/pages/ConsultationBooking/ConsultationBooking'));
const MedicalCardsPage = React.lazy(() => import('../views/pages/MedicalCardsPage/MedicalCardsPage'));
const Notifications = React.lazy(() => import('../views/pages/Notifications/Notifications'));
const CardInfo = React.lazy(() => import('../views/pages/CardInfo/CardInfo'));
const NewCardPage = React.lazy(() => import('../views/pages/NewCardPage/NewCardPage'));
const AnalysesAndDrugsBooking = React.lazy(() => import('../views/pages/AnalysesBooking/AnalysesAndDrugsBooking'));
// const ChatModeObserver = React.lazy(() => import('../chatHoc/ChatModeObserver'));

const AllConsultations = React.lazy(() => import('../views/pages/AllConsultations/AllConsultations'));
const Subscriptions = React.lazy(() => import('../views/pages/Subscriptions/Subscriptions'));
const SuccessPaymentPage = React.lazy(() => import('../views/pages/SuccessPaymentPage/SuccessPaymentPage'));
const DoctorsPage = React.lazy(() => import('../views/pages/DoctorsPage/DoctorsPage'));


const AllRoutes = (props) => {
  const currentPath = localStorage.getItem('path1')
  const defaultPath = currentPath !== '/' && (currentPath || '/clinic')
  
  return (
    <React.Suspense fallback={<div></div>}>
      <div className='mainContentContainer'>
        <Routes>
          <Route path='*' element={<Navigate to={routers.clinic}/>}/>
          <Route
            path={routers.clinic}
            exact
            element={<Clinic isSearching={props.isSearching} />}
          />
          <Route
            path={routers.supportChat}
            exact
            element={<ChatModeObserver supportMode />}
          />
          <Route
            path={routers.subscriptions}
            exact
            element={<Subscriptions />}
          />
          <Route
            path={routers.consultations}
            exact
            element={<AllConsultations />}
          />
          <Route
            path={routers.doctorChat}
            exact
            element={<ChatModeObserver />}
          />
          <Route
            path={routers.medicalCards}
            exact
            element={<MedicalCardsPage />}
          />
          <Route
            path={routers.notifications}
            exact
            element={<Notifications />}
          />
          <Route
            path={routers.consultationBooking}
            exact
            element={<ConsultationBooking />}
          />
          <Route
            path={routers.cardInfo}
            exact
            element={<CardInfo />}
          />
          <Route
            path={routers.newCard}
            exact
            element={<NewCardPage />}
          />
          {/* <Route
            path={routers.drugs}
            exact
            element={<AnalysesAndDrugsBooking />}
          /> */}
          <Route
            path={routers.successConsultation}
            exact
            element={<SuccessPaymentPage consultation />}
          />
          <Route
            path={routers.successSubscription}
            exact
            element={<SuccessPaymentPage subscription />}
          />
          <Route
            path={routers.doctors}
            exact
            element={<DoctorsPage />}
          />
          <Route path='/' element={<Navigate to={defaultPath || '/clinic'} />}/>
        </Routes>
      </div>
    </React.Suspense>
  )
}


export default AllRoutes