import React, { useState, useEffect } from "react";
import dropdown from "../../../style/icons/Navbar/dropdown.svg";
import SearchResults from "../SearchResults/SearchResults";
import { logOut } from "../../../redux/auth/auth-operations";
import "./navbar.scss";
import { connect } from "react-redux";
import moment from "moment";
import i18n from "i18next";
import { changeLang } from "../../../redux/lang/lang-actions";
import { useTranslation } from "react-i18next";
import patientActive from "../../../style/icons/Subscription/patient_active.svg";
import {
  bookSubscription,
  getSubscriptionInfo,
  getSubscriptionList,
} from "../../../redux/subscription/subscription-operations";
import SubscriptionCards from "./SubscriptionCards/SubscriptionCards";
import DeleteAccountDialog from "./DeleteAccountDialog/DeleteAccountDialog";
import UserTool from "./UserTool/UserTool";

import { LoadingComponent } from "../../pages/ConsultationBooking/ConsultationBooking";
import searchIcon from "../../../style/icons/Shevron/searchIcon.svg";
import { getUserMainCardSelector } from "../../../redux/auth/auth-selectors";
import { openFishka } from "../../../redux/fishka/fishka-actions";
import { getSymptoms } from "../../../redux/symptoms/symptoms-operations";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import useDebounce from "../../../hooks/useDebounce";
import { clearSearch } from "../../../redux/specializations/specializations-actions";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import useStyles from "./styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getReseted } from "../../../redux/specializations/specializations-selectors";
import { openPromocodeModal } from "../../../redux/banners/banners-actions";
import { useLocation } from "react-router-dom";
const languages = ["ua", "en"];

const Navbar = ({
  subscription,
  user,
  mainCard,
  loading,
  getSubscriptionInfo,
  getSubscriptionList,
  subscriptionList,
  openPromocodeModal,
  bookSubscription,
  symptoms,
  isSubscribed,
  searchLoading,
  openFishka,
  getSymptoms,
  clearSearch,
  reseted,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [cardListOpen, setCardListOpen] = useState(false);
  const [deleteAccountPopupOpen, setDeleteAccountPopupOpen] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [promocodeOpen, setPromocodeOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();

  useUpdateEffect(() => {
    if (!isSearching) {
      window.addEventListener("keydown", closeSearch);
    }
    return () => {
      if (isSearching) {
        window.removeEventListener("keydown", closeSearch);
      }
    };
  }, [isSearching]);

  React.useEffect(() => {
    if (location.pathname.includes("clinic")) return;
    clearSearch();
    setQuery("");
  }, [location.pathname]);

  useUpdateEffect(() => {
    setQuery("");
  }, [reseted]);

  useEffect(() => {
    if (!openMenu) {
      setCardListOpen(false);
    }
  }, [openMenu]);

  useEffect(() => {
    if (!listIsOpen) setCardId(null);
  }, [listIsOpen]);

  useEffect(() => {
    if (cardId) setListIsOpen(true);
  }, [cardId]);

  const browserLang = navigator.language || navigator.userLanguage;
  const defaultLang = browserLang === 'en-US' ? 'en' : 'ua'
  const lang = localStorage.getItem("i18nextLng") || defaultLang;
  const currentLang = lang === 'ru' ? defaultLang : lang;

  const languageChangeHandler = (e) => {
    e.preventDefault();
    moment.locale(e.target.value);
    changeLang(e.target.value);
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    window.location.reload();
  };

  const bookWithPromocode = (promocode) => {
    const requestBody = {
      promocode: promocode,
    };
    bookSubscription(subscriptionId, cardId, requestBody);
  };

  const closeSearch = (e) => {
    if (e.code === "Escape") {
      setIsSearching(false);
      setQuery("");
    }
  };

  const handleOpenPromocode = () => {
    openPromocodeModal(true);
  };

  useDebounce(
    () => {
      if (!query) return;
      getSymptoms(query);
    },
    600,
    [query, isSearching]
  );

  const handleSearch = (evt) => {
    if (evt.target) {
      setQuery(evt.target.value);
      setIsSearching(evt.target.value);
    } else {
      setQuery(evt);
      setIsSearching(false);
    }
  };

  useUpdateEffect(() => {
    if (!query) clearSearch();
  }, [query]);

  return (
    <>
      <div className="navbarContainer">
        <div className="toolBox">
          <div className="languageBox">
            {/* {currentLang === 'ru' && <img className='langIcon' width='25' height='20' src={ ruIcon } alt='language-icon'/>}
            {currentLang === 'ua' && <img className='langIcon' width='25' height='20' src={ uaIcon } alt='language-icon'/>}
            {currentLang === 'en' && <img className='langIcon' width='25' height='20' src={ engIcon } alt='language-icon'/>} */}
            <Select
              value={currentLang}
              onChange={languageChangeHandler}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
              disableUnderline
              defaultValue={currentLang}
              MenuProps={{
                classes: { paper: classes.menuStyle },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {languages.map((el, i) => {
                return (
                  <MenuItem
                    key={i}
                    className={classes.selectProvider}
                    value={el}
                  >
                    {el.toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="searchBox">
            <img className="searchIcon" src={searchIcon} alt="" />
            <input
              onChange={handleSearch}
              className="searchInput"
              type="text"
              value={query}
              placeholder={t("whatIsYourDisturbing")}
              onBlur={() => {
                setTimeout(() => {
                  setIsSearching(false);
                }, 200);
              }}
            />
            {query && !searchLoading && (
              <CloseTwoToneIcon
                onClick={() => {
                  setQuery("");
                  setIsSearching(false);
                }}
                className={classes.icon}
                alt="cancelIcon"
              />
            )}
            {searchLoading && (
              <div className="symptomsLoadingBox">
                <LoadingComponent loading={true} width={27} height={27} />
              </div>
            )}
          </div>
          {/* <img
            className="loyaltyLogo"
            onClick={() => openFishka()}
            src={fiskaMainCircle}
            alt="fiska"
            title="Fishka"
          /> */}
          {/* <Button
            withIcon
            icon={promocodeIcon}
            text={"Промокод"}
            className="btn promocode"
            onClick={handleOpenPromocode}
          /> */}
        </div>
        <div
          onClick={() => setOpenMenu(!openMenu)}
          className="userInfoContainer"
        >
          {!openMenu && (
            <>
              <img
                width="40"
                height="40"
                className="userImage"
                src={
                  user && user.photoUrl
                    ? user.photoUrl.toString()
                    : patientActive
                }
                alt="userPhoto"
              />
              <div className="userNameMain">{`${user && user.lastName} ${
                user && user.firstName
              }`}</div>
            </>
          )}
          <div className="dropImageContainer">
            <img
              className={`dropImage ${openMenu ? "rotate" : ""}`}
              src={dropdown}
              alt="dropdown"
            />
          </div>
        </div>
        {isSearching && (
          <SearchResults
            value={query}
            symptoms={symptoms}
            handleSearch={handleSearch}
          />
        )}
        {openMenu && (
          <UserTool
            mainCard={mainCard}
            setOpenMenu={setOpenMenu}
            listIsOpen={listIsOpen}
            setCardListOpen={setCardListOpen}
            setDeleteAccountPopupOpen={setDeleteAccountPopupOpen}
            user={user}
            promocodeOpen={promocodeOpen}
            cardListOpen={cardListOpen}
            languageChangeHandler={languageChangeHandler}
            lang={lang}
            t={t}
            subscription={subscription}
            isSubscribed={isSubscribed}
          />
        )}
        {openMenu && cardListOpen && (
          <SubscriptionCards
            setOpenMenu={setOpenMenu}
            t={t}
            setCardListOpen={setCardListOpen}
            setCardId={setCardId}
          />
        )}
        {openMenu && deleteAccountPopupOpen && (
          <DeleteAccountDialog
            setOpenMenu={setOpenMenu}
            setDeleteAccountPopupOpen={setDeleteAccountPopupOpen}
            t={t}
          />
        )}
        {/* {openMenu && listIsOpen && (
          <SubscriptionList
            setSubscriptionId={setSubscriptionId}
            setPromocodeOpen={setPromocodeOpen}
            setListIsOpen={setListIsOpen}
            subscriptionList={subscriptionList}
            t={t}
          />
        )}
        {openMenu && promocodeOpen && (
          <SubscriptionPromocode
            t={t}
            loading={loading}
            bookWithPromocode={bookWithPromocode}
            setPromocodeOpen={setPromocodeOpen}
          />
        )} */}
        {/* {openMenu && isBooked && (
          <ActivationSuccess
            text={t("promocodeSuccess")}
            t={t}
          />
        )} */}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getSubscriptionInfo,
  openPromocodeModal,
  logOut,
  changeLang,
  getSubscriptionList,
  bookSubscription,
  openFishka,
  getSymptoms,
  clearSearch,
};

const mapStateToProps = (state) => ({
  user: state?.user?.userInfo,
  mainCard: getUserMainCardSelector(state),
  subscription: state?.subscription?.subscription,
  loading: state?.subscription?.loading,
  info: state?.subscription?.info,
  isSubscribed: state?.subscription?.isSubscribed,
  subscriptionList: state?.subscription?.subscriptionList,
  isBooked: state?.subscription?.isBooked,
  searchLoading: state?.symptoms?.loading,
  symptoms: state?.symptoms?.symptoms,
  reseted: getReseted(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
