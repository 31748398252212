import React, { useState, useEffect } from "react";

import DatePickerUI from "../ui/DatePicker/DatePicker";

import "../formComponents/formComponents.scss";
import moment from "moment";

const DatePicker = ({
  label,
  input,
  meta: { touched, error, visited },
  placeholder,
  disabled,
  inputProps,
  validDate,
  tagsMode,
  locale,
  readOnly,
  isValidDate = () => true,
  maxDate = 2030,
  defaultValue,
  ...props
}) => {
  const [selectedDates, setSelectedDates] = useState([])
  const [date, setDate] = useState([""]);
  const [stopSelecting, setStopSelecting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (selectedDates.length && tagsMode) {
      input.onChange({
        selectedDates: selectedDates,
      })
    }
  }, [selectedDates, tagsMode])

  useEffect(() => {
    input.onChange(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (input.value === "") {
      setSelectedDates([]);
    }
  }, [input.value]);

  const deleteDate = (index) => {
    setSelectedDates((selectedDates) =>
      selectedDates.filter((el, i) => (i !== index ? el : ""))
    );
  };

  const getIsSelected = (value) =>
    selectedDates.some((date) => date === value);

  const getCurrentValue = () => {
    const length = input?.value?.selectedDates?.length || 0
    return length
      ? input.value.selectedDates[length - 1]
      : input.value
  }

  const inputId = `input${input.name}`;
  return (
    <div className={"formField picker"}>
      {label ? <label>{label}</label> : ""}
      {placeholder && (
        <div className={`placeholder`}>{placeholder}</div>
      )}
      <DatePickerUI
        {...input}
        id={inputId}
        date={date}
        setDate={setDate}
        readOnly={readOnly}
        name={inputProps && inputProps.name}
        value={getCurrentValue()}
        // value={
        //   (input && input.value && input.value.value) || (input && input.value)
        // }
        onBlur={() => input.onBlur(input.value)}
        onChange={
          (value) => {
            if (moment(value).format("DD/MM/YYYY") !== "Invalid date") {
              if (typeof value === "object") {
                input.onChange(moment(value._d).format("DD/MM/YYYY"));
                if (tagsMode) {
                  setSelectedDates(prev => [...prev, moment(value._d).format("DD/MM/YYYY")])
                }
                return;
              }
              if (typeof value === 'string' && tagsMode) {
                input.onChange(value);
                if(getIsSelected(value)) return
                setSelectedDates(prev => [...prev, value])
              }
            }
            input.onChange(value);
          }
        }
        placeholder={placeholder}
        touched={touched}
        error={error}
        visited={visited}
        disabled={disabled}
        tagsMode={tagsMode}
        validDate={validDate}
        selectedDates={selectedDates}
        locale={locale || localStorage.getItem("lang")}
        isValidDate={isValidDate}
        maxDate={maxDate}
        {...props}
      >
        {/* {selectedDates.length > 0 && (
          <div className="selectedDates">
            {selectedDates &&
              selectedDates.map((item, index) => (
                <span className="date" key={index}>
                  {item}
                  <img
                    src={deleteIconRed}
                    alt=""
                    onClick={() => deleteDate(index)}
                  />
                </span>
              ))}
          </div>
        )} */}
      </DatePickerUI>
      {disabled && <div className="hiddingEl" />}
      {visited && error && <span className={"formItemError"}>{error}</span>}
    </div>
  );
};

export default DatePicker;