export const routers = {
  home: '/',
  clinic: '/clinic',
  consultations: '/consultations/:cardId?',
  notifications: '/notifications',
  medicalCards: '/medical-cards',
  consultationBooking: '/clinic/:id?/:limited?',
  cardInfo: '/medical-cards/:cardId?',
  newCard: '/new-card',
  analysesBooking: '/analysis',
  supportChat: '/support',
  doctorChat: '/appointment',
  subscriptions: '/subscriptions/:cardId',
  // drugs: '/drugs/:appointmentId?/:cardId?',
  success: '/payment/success',
  successPayment: '/success/payment',
  successConsultation: "/consultations/payment/success",
  successSubscription: "/subscriptions/payment/success",
  doctors: '/doctors/:page',
}