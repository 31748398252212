import userReducer from './auth/auth-reducers'
import specializationsReducer from './specializations/specializations-reducers'
import symptomsReducer from './symptoms/symptoms-reducers'
import networkErrorReducer from './networkError/networkError-reducers'
import analysisReducers from './analysis/analysis-reducers'
import consultationReducers from './consultations/consultations-reducers'
import langReducer from './lang/lang-reducers'
import doctorsReducers from './doctors/doctors-reducer'
import addressesReducers from './addresses/addresses-reducer'
import hospitalsReducers from './hospitals/hospitals-reducer'
import relationsReducer from './relations/relations-reducers'
import notificationsReducer from './notifications/notifications-reducers'
import subscriptionReducer from './subscription/subscription-reducer'
import chatReducer from './chat/chat-reducers'
import popupReducers from './popup/popup-reducers'
import {routerReducer} from 'react-router-redux'
import cardsReducer from './cards/cards-reducers'
import fishkaReducers from './fishka/fishka-reducers'
import drugsReducer from './drugs/drugs-reducers'
import bannersReducers from './banners/banners-reducers'
import {reducer as formReducer} from 'redux-form'
import {
    configureStore,
    combineReducers,
} from '@reduxjs/toolkit'
import socketMiddleware from './socket/webSocketMiddleware'
import newSocketMiddleware from './socket/middleware'


const rootReducer = combineReducers({
    user: userReducer,
    specializations: specializationsReducer,
    symptoms: symptomsReducer,
    networkError: networkErrorReducer,
    analysis: analysisReducers,
    consultations: consultationReducers,
    doctors: doctorsReducers,
    addresses: addressesReducers,
    hospitals: hospitalsReducers,
    form: formReducer,
    lang: langReducer,
    relations: relationsReducer,
    notifications: notificationsReducer,
    subscription: subscriptionReducer,
    chat: chatReducer,
    popup: popupReducers,
    routing: routerReducer,
    cards: cardsReducer,
    fishka: fishkaReducers,
    drugs: drugsReducer,
    banners: bannersReducers,
})

const middleware = (getDefaultMiddleware) => [...getDefaultMiddleware(), newSocketMiddleware];

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV === 'development',
})

export default store