import { useTranslation } from 'react-i18next'
import successIcon from '../../../style/icons/Success/successIcon.svg'
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking'
import ErrorIcon from "@material-ui/icons/Error"
import Button from '../ui/Button/Button'
import './bookingSuccess.scss'
import { useNavigate } from 'react-router-dom'

const BookingSuccessComponent = ({
  text,
  loading,
  onClick,
  error,
  containerStyles = {},
  buttonStyles = {}
}) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <div
      style={containerStyles}
      className='bookingSuccessContainer'
    >
      <LoadingComponent loading={loading}>
        <div className='bookingSuccessContent'>
          {error && (
            <ErrorIcon style={{color: "red", width: "50px", height: "50px", opacity: "0.8"}}/>
          )}
          {!error && (
            <img src={successIcon} alt="successIcon" />
          )}
          <div className='bookingText'>{!error ? text : t("bookingError")}</div>
        </div>
        <div className='analysisStepButton'>
          <Button
            onClick={() => onClick ? onClick() : navigate('/clinic')}
            type='button'
            className='btn'
            text={!error ? t("goodButton") : t("bookAgain")}
          />
        </div>
      </LoadingComponent>
    </div>
  )
}

export default BookingSuccessComponent;