import React from 'react';
import { createPortal } from 'react-dom';
import './backgroundCall.scss';
import ReactModal from 'react-modal-resizable-draggable';
import { connect } from 'react-redux';
import { muteAudio, muteVideo, sendPeerTracks, startVideoChat, endCall, moveCallToBackground } from '../../../redux/chat/chat-actions';
import videoActive from '../../../style/icons/VideoChat/videoActive.svg'
import videoMuted from '../../../style/icons/VideoChat/videoMuted.svg'
import microActive from '../../../style/icons/VideoChat/microActive.svg'
import microMuted from '../../../style/icons/VideoChat/microMuted.svg'
import end_сall from '../../../style/icons/VideoChat/end_call.svg'
import make_full_screen from "../../../style/icons/VideoChat/make_full_screen_video.svg";
import close_full_screen from "../../../style/icons/VideoChat/close_full_screen_video.svg";
import chatIcon from "../../../style/icons/VideoChat/chat.svg";
import reject from '../../../style/icons/Call/reject.svg'

const BackgroundCall = ({
  muteVideo,
  muteAudio,
  videoStatus,
  audioStatus,
  endCall,
  isActiveCall,
  isBackgroundNeeded,
  moveCallToBackground,
}) => {
  const patientRef = React.useRef(null)
  const doctorRef = React.useRef(null)

  React.useEffect(() => {
    if (isBackgroundNeeded && patientRef.current && patientRef.current) {
      // setTimeout(() => {
        moveCallToBackground({
          patientRef,
          doctorRef,
        })
      // }, 1000)
    }
  }, [isBackgroundNeeded, patientRef.current, doctorRef.current])
  // React.useEffect(() => {
  //   navigator.mediaDevices.getUserMedia({ video: true, audio: true })
  //     .then(stream => {
  //       patientRef.current.srcObject = stream
  //       doctorRef.current.srcObject = stream
  //     })
  // }, [])

  return createPortal(
    <ReactModal
      initWidth={350}
      initHeight={250}
      onFocus={() => {}}
      className={"my-modal-custom-class"}
      onRequestClose={() => { }}
      isOpen={isActiveCall && isBackgroundNeeded}
      top={20}
      right={20}
    >
      <div className="videoContainer">
        <video
          ref={patientRef}
          id='patientBackgroundVideo'
          playsInline
          autoPlay
          className='patientBackgroundVideo'
        />
        <video
          ref={doctorRef}
          id='doctorBackgroundVideo'
          playsInline
          autoPlay
          className='doctorBackgroundVideo'
        />
      </div>
      <div className="toolPanel">
        <img
          width='40'
          height='40'
          onClick={() => muteVideo()}
          src={videoStatus ? videoActive : videoMuted}
          alt="video-tool"
          title={'mute video'}
        />
        <img
          width='40'
          height='40'
          onClick={() => muteAudio()}
          src={audioStatus ? microActive : microMuted}
          alt="micro-tool"
          title={'mute audio'}
        />
        <img
          width='40'
          height='40'
          onClick={() => endCall()}
          // style={{ width: "58px", height: "58px" }}
          src={reject} alt="end-call"
          title={'end call'}
        />
      </div>
    </ReactModal>
    , document.getElementById("call"));
}

const mapStateToProps = (state) => ({
  videoStatus: state.chat.videoStatus,
  audioStatus: state.chat.audioStatus,
  isActiveCall: state.chat.isPeerConnected,
  isBackgroundNeeded: state.chat.isBackgroundCallNeeded,
})
const mapDispathToProps = {
  startVideoChat,
  muteVideo,
  muteAudio,
  endCall,
  moveCallToBackground,
}

export default connect(mapStateToProps, mapDispathToProps)(BackgroundCall);
