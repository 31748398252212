import React from "react";
import logo from "../../../style/icons/logo.svg";
import sync from "../../../style/icons/sync.svg";
import { SimpleForm } from "../../components/formComponents/SimpleForm/SimpleForm";
import SimpleInput from "../../components/formComponents/SimpleInput/SimpleInput";
import Footer from "../../components/Footer/Footer";
import "./auth.scss";
import Button from "../../components/ui/Button/Button";
import { parserForm } from "../../../utils/parserForm";
import { connect } from "react-redux";
import { login, sendCode, getCaptcha } from "../../../redux/auth/auth-operations";
import { useTranslation } from "react-i18next";
import { RefToMobile } from "../../components/RefToMobile/RefToMobile";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PhoneInput from "../../components/formComponents/PhoneInput/PhoneInput";
import { Field } from "redux-form";
import api from "../../../api";

function Auth({ login, sendCode, isSentCode }) {
  const [phoneAgain, setPhoneAgain] = React.useState("");
  const [captchaAgain, setCaptchaAgain] = React.useState("");
  const [captchaUrl, setCaptchaUrl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [codeLoading, setCodeLoading] = React.useState(false);
  const [loginLoading, setLoginLoading] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    return () => {
      setPhoneAgain("");
      setChecked(false);
    };
  }, []);

  const formSubmit = (evt, resetForm, setError) => {
    const { phone, captcha, code } = parserForm(evt);
    if (!isSentCode) {
      setCodeLoading(true);
      setPhoneAgain(phone);
      setCaptchaAgain(captcha);
      sendCode(phone, captcha)
        .catch((e) => {
          if(e.response.status === 422) {
            updateCaptcha(phone);
          }
        })
        .finally((response) => {
          setCodeLoading(false);
        });
    } else {
      setLoginLoading(true);
      login({
        phone,
        code,
        bonusFishkaAccept: checked,
      }).finally(() => setLoginLoading(false));
    }
  };

  React.useEffect(() => {
    localStorage.setItem("fishka", checked);
  }, [checked]);

  const handleRepeat = () => {
    setLoading(true);
    sendCode(phoneAgain, captchaAgain).then(() => setLoading(false));
  };

  const updateCaptcha = (phone) => {
    setLoading(true);
    api.user.getCaptcha({ 'phone': phone && phoneAgain })
    .then((response) => {
      setCaptchaUrl(response.data.url);
      setLoading(false);
    });
  }

  return (
    <>
      <RefToMobile />
      <div className="authContainer">
        <img className="authLogo" src={logo} alt="logo" />
        <h1 className="registration">{t("authReg")}</h1>
        <div className="wholeContainer">
          <div className="formContainer">
            <SimpleForm
              handleSubmit={formSubmit}
              className="loginForm"
              autoComplete="off"
              novalidate
            >
              <div className="telContainer">
                <div className="labelText">{t("phoneIsRequire")}</div>
                <div
                  style={{
                    width: "340px",
                    height: "100%",
                    maxHeight: "70px",
                    minHeight: "60px",
                    marginBottom: "10px",
                  }}
                >
                  <PhoneInput
                    name={"phone"}
                    defaultValue={null}
                    placeholder={t("phoneNumber")}
                    required
                  />
                </div>
              </div>
              {captchaUrl && !isSentCode && (
                <div className="numberContainer">
                  <div className="labelText">Введіть текст з картинки</div>
                  <div
                    style={{
                      width: "340px",
                      height: "100%",
                      maxWidth: "100%",
                      minHeight: "60px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="labelText">
                      <img src={captchaUrl} alt="Captcha" style={{ maxWidth: "100%" }}></img>
                    </div>
                    <div className="repeatContainer" style={{ marginBottom: "10px" }}>
                      <img
                        width="35"
                        height="43"
                        className={`${loading ? "repeatCodeImg" : ""}`}
                        onClick={updateCaptcha}
                        src={sync}
                        alt="repeat code"
                      />
                    </div>
                    <SimpleInput
                      name="captcha"
                      errorMessage={t("incorrectCode")}
                      errorRequire={t("requiredValidate")}
                      placeholder={t("code")}
                      required
                      AuthFormat
                    />
                  </div>
                </div>
              )}
              {isSentCode && (
                <div className="numberContainer">
                  <div className="labelText">{t("InputSmsCode")}</div>
                  <div
                    style={{
                      width: "340px",
                      height: "100%",
                      maxHeight: "70px",
                      minHeight: "60px",
                      marginBottom: "10px",
                    }}
                  >
                    <SimpleInput
                      autoFocus
                      name="code"
                      pattern="[0-9]{4}$"
                      errorMessage={t("incorrectCode")}
                      errorRequire={t("requiredValidate")}
                      placeholder={t("code")}
                      required
                      AuthFormat
                      maxLength="4"
                    />
                  </div>
                </div>
              )}
              <div className="buttonContainer">
                <Button
                  type="submit"
                  className="btn"
                  text={isSentCode ? t("logIn") : t("sendCode")}
                  buttonLoading={isSentCode ? loginLoading : codeLoading}
                />
              </div>
            </SimpleForm>
          </div>
          {isSentCode && (
            <div className="repeatContainer">
              <div className="repeat">{t("ResendCode")}</div>
              <img
                width="35"
                height="43"
                className={`${loading ? "repeatCodeImg" : ""}`}
                onClick={handleRepeat}
                src={sync}
                alt="repeat code"
              />
            </div>
          )}
          {/* {isSentCode && (
            <div className="authCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    onChange={(e) => setChecked(e.target.checked)}
                    color="primary"
                    style={{ color: "#3288cd" }}
                  />
                }
                label={t("fishkaPolicyText")}
                labelPlacement="end"
              />
            </div>
          )} */}
          <div className="infoContainer">
            {t("informPart1")}
            <a
              target="_blank"
              className="hrefs"
              rel="noreferrer"
              href="https://dobrodoc-plus.com/yuridichna-informatsiya"
            >
              {t("informPart2")}
            </a>
            {t("informPart3")}
            <a
              target="_blank"
              rel="noreferrer"
              className="hrefs"
              href="https://dobrodoc.com/wp-content/uploads/2020/07/policy.pdf"
            >
              {t("informPart4")}
            </a>
            .
          </div>
        </div>
        <div className="footerArea">
          <Footer />
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  login,
  sendCode,
};
const mapStateToProps = ({ user }) => ({
  isSentCode: user && user.isSentCode,
});
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
