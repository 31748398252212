import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { clearUnreadedCount } from "../redux/notifications/notifications-actions";
import chatModes from "../utils/chatModes";
import CallChat from "../views/components/CallChat/CallChat";
import Chat1 from "../views/components/Chat1/Chat1";
import VideoChat from "../views/components/VideoChat/VideoChat";
import "../index.scss";
import {
  getCurrentConsultationSelector,
  getIsGotInfo,
  getWasCancalled,
} from "../redux/consultations/consultations-selectors";
import {
  SOCKET_HOST,
  SOCKET_HOST_CALL,
  SOCKET_HOST_SUPPORT,
} from "../views/components/Chat1/connection";
import info from "../utils/info";
import {
  allowMicrophone,
  changeChatMode,
  clearMessages,
  endCall,
  isBackgroundCallNeeded,
  leaveChatRoom,
  moveCallToBackground,
  openCallFromBackground,
  resetSearch,
  sendGreetingMessage,
  updateCall,
  wsCallConnection,
  wsCallDisconnect,
  wsSupportConnect,
  wsSupportDisconnect,
} from "../redux/chat/chat-actions";
import {
  resetAnamnesis,
  resetCurrentConsultation,
  setCancelledFlag,
} from "../redux/consultations/consultations-actions";
import AudioChat from "../views/components/AudioChat/AudioChat";
import {
  checkForAudioAndVideoPermission,
  requestForAudioPermission,
  requestForVideoPermission,
} from "./permissions";
import useUpdateEffect from "../hooks/useUpdateEffect";
import {
  getConsultAnamnesis,
  getConsultationInfo,
} from "../redux/consultations/consultations-operations";
import {
  getAppoitmentChatHistory,
  getSupportChatHistory,
} from "../redux/chat/chat-operations";
import {
  getChatHistory,
  wsConnect,
  wsDisconnect,
} from "../redux/socket/socketActions";
import { getChatMessages } from "../redux/chat/chat-selectors";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ChatModeObserver = ({
  chatMode,
  supportMode,
  clearUnreadedCount,
  wasCancelled,
  setCancelledFlag,
  allowMicrophone,
  leaveChatRoom,
  isActiveCall,
  moveCallToBackground,
  isBackgroundCallNeeded,
  updateCall,
  resetSearch,
  currentConsultation,
  getConsultationInfo,
  getConsultAnamnesis,
  resetAnamnesis,
  resetCurrentConsultation,
  clearMessages,
  openCallFromBackground,
  endCall,
  wsConnect,
  getChatHistory,
  wsDisconnect,
  sendGreetingMessage,
  messages,
  currentPage,
}) => {
  const [fullScreenChat, setFullScreenChat] = useState(false);
  const [chatOpenedWithVideo, setChatOpenedWithVideo] = useState(true);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const callType = React.useRef(null);
  const doctorVideoRef = React.useRef(null);
  const userVideoRef = React.useRef(null);
  const doctorAudioRef = React.useRef(null);
  ///DATA FROM URL
  const urlQueries = React.useRef({
    appointmentId: searchParams.get("id"),
    doctorId: searchParams.get("doctorId"),
    doctorSpecialtyId: searchParams.get(
      "doctorSpecialtyId"
    ),
    callType: searchParams.get("callType"),
  });

  ///CHECK FOR REQUEST DEVICES PERMISSION
  useEffect(() => {
    clearUnreadedCount();
    requestForVideoPermission();
    requestForAudioPermission().then((isAllowed) => allowMicrophone(isAllowed));
    return () => wsDisconnect();
  }, []);

  useEffect(() => {
    const savedChatValue = JSON.parse(localStorage.getItem("currentChatValue"));
    if (savedChatValue && savedChatValue.isSupportMode !== supportMode) {
      localStorage.removeItem("currentChatValue");
    }
  });

  ///SENDING GREETING MESSAGE WHEN START CHAT
  // useEffect(() => {
  //   // const isNoMessages = messages && !messages.length
  //   // const isConsult = currentConsultation && currentConsultation.doctor
  //   // if (isNoMessages && isConsult && currentPage === 0 && !supportMode) {
  //   const doctor = currentConsultation?.doctor?.employee?.fullName;
  //   const date = currentConsultation?.start;
  //   if (doctor && date) {
  //     console.log("edfwefwefw4w");
  //     sendGreetingMessage({
  //       doctor,
  //       date,
  //       appId: urlQueries.current.appointmentId,
  //     });
  //   }

  //   // }
  // }, [currentConsultation]);

  ////CONNECT TO SOCKET AND GET HISTORY
  useEffect(() => {
    clearMessages();
    if (supportMode) {
      wsConnect({
        doctorVideoRef,
        userVideoRef,
        doctorAudioRef,
      });
    }
    if (!supportMode && currentConsultation) {
      const doctor = currentConsultation?.doctor?.employee?.fullName;
      const date = currentConsultation?.start;
      sendGreetingMessage({
        doctor,
        date,
        appId: urlQueries.current.appointmentId,
      });
      wsConnect({
        doctorVideoRef,
        userVideoRef,
        doctorAudioRef,
      });
    }
  }, [supportMode, currentConsultation]);

  /////GET REDIRECT FROM BACKGROUND NOTIFICATION
  useEffect(() => {
    const urlCallType = urlQueries.current.callType;
    if (urlCallType) {
      callType.current = urlCallType;
      const queries = location.search
        .split("&")
        .filter((el, i, arr) => i !== arr.length - 1)
        .join("&");
      openCallFromBackground({ callType: urlCallType, chatMode: "call" });
      navigate(`${location.pathname}${queries}`);
    }
  }, []);

  ////CONNECT TO APPOINTMENT CHAT SOCKET, GETTING NEEDED INFO
  useEffect(() => {
    if (supportMode) return;
    getConsultationInfo(urlQueries.current.appointmentId);
    getConsultAnamnesis(urlQueries.current.appointmentId);
    return () => {
      resetAnamnesis();
      resetCurrentConsultation();
    };
  }, []);

  useUpdateEffect(() => {
    return () => {
      if (isActiveCall) {
        // isBackgroundCallNeeded(true)
      } else {
        // leaveChatRoom()
        wsCallDisconnect();
      }
    };
  }, [isActiveCall]);

  useEffect(() => {
    return () => {
      resetSearch();
      if (isActiveCall) {
        // isBackgroundCallNeeded(true)
      } else {
        leaveChatRoom();
        // wsSupportDisconnect()
      }
    };
  }, []);

  useEffect(() => {
    if (wasCancelled) {
      navigate("/consultations");
      setCancelledFlag(false);
    }
  }, [wasCancelled]);

  const handleFullScreen = () => {
    setFullScreenChat((prev) => !prev);
  };

  useEffect(() => {
    if (!fullScreenChat) {
      setChatOpenedWithVideo(true);
    }
  }, [fullScreenChat]);

  ///SHOW ONLY CHAT IN SUPPORT
  if (supportMode) {
    return (
      <Chat1 supportMode={supportMode} consultationInfo={currentConsultation} />
    );
  }

  switch (chatMode) {
    case chatModes.CHAT:
      return (
        <>
          <Chat1
            supportMode={supportMode}
            consultationInfo={currentConsultation}
            appointmentId={urlQueries.current.appointmentId}
          />
        </>
      );
    case chatModes.CALL:
      return <CallChat callType={callType.current} />;
    case chatModes.VIDEO:
      return (
        <div
          className={`wholeChatContainer ${
            fullScreenChat ? "fullscreen" : ""
          } ${!chatOpenedWithVideo ? "fullWidth" : ""}`}
        >
          <VideoChat
            doctorVideoRef={doctorVideoRef}
            userVideoRef={userVideoRef}
            chatOpenedWithVideo={chatOpenedWithVideo}
            setChatOpenedWithVideo={setChatOpenedWithVideo}
            fullScreenChat={fullScreenChat}
            handleFullScreen={handleFullScreen}
          />
          <Chat1
            appointmentId={urlQueries.current.appointmentId}
            fullScreenChat={fullScreenChat}
            setChatOpenedWithVideo={setChatOpenedWithVideo}
            chatOpenedWithVideo={chatOpenedWithVideo}
            consultationInfo={currentConsultation}
            sidebarMode
          />
        </div>
      );
    case chatModes.AUDIO:
      return (
        <div
          className={`wholeChatContainer ${fullScreenChat ? "fullscreen" : ""}`}
        >
          <CallChat doctorAudioRef={doctorAudioRef} isActive />
          <Chat1
            fullScreenChat={fullScreenChat}
            setChatOpenedWithVideo={setChatOpenedWithVideo}
            chatOpenedWithVideo={chatOpenedWithVideo}
            consultationInfo={currentConsultation}
            appointmentId={urlQueries.current.appointmentId}
            sidebarMode
          />
        </div>
      );
      default:
        return null;
  }
};

const mapStateToProps = (state) => ({
  chatMode: state.chat.chatMode,
  wasCancelled: getWasCancalled(state),
  isActiveCall: state?.chat?.isPeerConnected,
  currentConsultation: getCurrentConsultationSelector(state),
  messages: getChatMessages(state),
  currentPage: state.chat.currentPage,
});

export default connect(mapStateToProps, {
  clearUnreadedCount,
  wsCallConnection,
  wsSupportConnect,
  wsCallDisconnect,
  wsSupportDisconnect,
  setCancelledFlag,
  allowMicrophone,
  leaveChatRoom,
  moveCallToBackground,
  isBackgroundCallNeeded,
  updateCall,
  resetSearch,
  getConsultationInfo,
  getConsultAnamnesis,
  getSupportChatHistory,
  getAppoitmentChatHistory,
  resetAnamnesis,
  resetCurrentConsultation,
  clearMessages,
  endCall,
  openCallFromBackground,
  wsConnect,
  getChatHistory,
  sendGreetingMessage,
  wsDisconnect,
})(ChatModeObserver);
