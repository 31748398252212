import React from 'react';
import { Route, Navigate, Routes } from 'react-router';
import Auth from '../Auth/Auth';
import Registration from '../Registration/Registration';

const RegistrationAuthPage = ({ redirectTo }) => {
  const currentLocation = `${window.location.pathname}${window.location.search}`
  const isStorageNeeded = currentLocation !== '/login' && currentLocation !== '/registration'
  if (isStorageNeeded) {
    localStorage.setItem('path1', currentLocation)
  }

  return (
    <Routes>
      <Route path='/login' element={<Auth/>}/>
      <Route path='/registration' element={<Registration/>}/>
      <Route path='*' element={<Navigate to={{
        pathname: redirectTo,
        state: {refferrer: currentLocation}
      }} />}/>
    </Routes>
  );
}

export default RegistrationAuthPage;
