import atChat from '../style/icons/consultations/atChat.svg'
import phoneCall from '../style/icons/consultations/phoneCall.svg'
import videoCall from '../style/icons/consultations/videoCall.svg'
import moment from 'moment'
import home from '../style/icons/consultationTypes/atHome.svg'
import i18n from '../i18n'
import clinic from '../style/icons/consultationTypes/atClinic.svg'

export const countDrugsArray = Array(50).fill(0)

export const getConsultationTime = (time, notificationsMode = false) => {
  const consultationTime = moment(time).format("DD MMMM YYYY")
  const todayTime = moment(Date.now()).format("DD MMMM YYYY")
  const rightTime = moment(time).format("HH:mm")
  if (consultationTime === todayTime) {
    return `${i18n.t('today')} ${!notificationsMode ? i18n.t('at') : ""} ${rightTime}`
  }
  return `${consultationTime} ${!notificationsMode ? i18n.t('at') : ""} ${rightTime}`
}

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};
  
export const getMethodTitle = (method) => {
  console.log(method);
  switch (method) {
    case 0:
      return 'optionsServiceTypeInChat'
    case 1:
      return 'optionsServiceTypeCall'
    case 2:
      return 'optionsServiceTypeInVideo'
    case 3:
      return 'optionsServiceTypeAtHome'
    default:
      return 'optionsServiceTypeInHospital'
  }
}

export const getMethodImage = (method) => {
  switch (method) {
    case 0:
      return atChat
    case 1:
      return phoneCall
    case 2:
      return videoCall
    case 3:
      return home
    case 4:
      return clinic
    default:
      return
  }
}

export const getTypeTitle = (type) => {
  switch (type) {
    case 'online':
      return "bookConsultationOnline"
    case 'home':
      return "bookConsultationAtHome"
    case 'hospital':
      return "bookConsultationInClinic"
    default: return
  }
}

export const getStepCount = (type) => {
  switch (type) {
    case 'online':
      return 6
    case 'home':
      return 4
    case 'hospital':
      return 5
    default: return 5
  }
}

export const getMessageTime = (time) => moment(time).format("HH:mm")

export const getCurrentDate = () => moment().format("YYYY-MM-DDTHH:mm:ssZ")

export const getFileType = (fileType = "") => {
  if (fileType.includes("image")) {
    return "image";
  }
  if (fileType.includes("video")) {
    return "video";
  }
  if (
    fileType.includes("pdf") ||
    fileType.includes("application") ||
    fileType.includes("text")
  ) {
    return "file";
  }
};

export const getRelationTitleById = (id) => {
  switch (id) {
    case 1:
      return i18n.t("Me")
    case 3:
      return i18n.t("Son")
    case 2:
      return i18n.t("Wife")
    case 4:
      return i18n.t("Daughter")
    case 5:
      return i18n.t("Husband")
  }
}

const env = process.env;
const localUrl = env.REACT_APP_HOSTNAME_2;

const localMobileUrl = env.REACT_APP_HOSTNAME_MOBILE;
console.log(localMobileUrl)
const location = window.location.hostname.includes(localUrl);

export const getMobileUrl = () => {
  return localUrl ? localMobileUrl : false;
}
export const checkIfMobileUrl = () => {
  return location;
}


export const getSetFromLocalStorage = (key = "", expectedType = "") => {
  try {
    const result = localStorage.getItem(key)
    if (result) {
      const parsedResult = JSON.parse(result)
      if (typeof parsedResult === expectedType) {
        return parsedResult
      } else {
        return null
      }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const removeKeysFromStorage = (keys = []) => {
  keys.forEach((key) => localStorage.removeItem(key))
}