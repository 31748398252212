import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import { IconButton } from "@material-ui/core";
import "./transition.scss";
import { useTranslation } from "react-i18next";
import Button from "../ui/Button/Button";
import promocodeIcon from "../../../style/icons/Promocode/promocodes-icon.svg";
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import { sleep } from "../../../utils/sleep";
import { debounce } from "lodash";
import api from "../../../api";
import Spinner from "../ui/Spinner/Spinner";
import Animationlist from "../AnimatedList/AnimatedList";
import { useDispatch } from "react-redux";
import { setSpecializationsDiscount } from "../../../redux/specializations/specializations-actions";
import { openPromocodeModal } from "../../../redux/banners/banners-actions";
import { getUserCards } from "../../../redux/auth/auth-operations";
import { useSelector } from "react-redux";
import { getUserCardsSelector } from "../../../redux/auth/auth-selectors";
import BookingSuccessComponent from "../BookingSuccessComponent/BookingSuccessComponent";

const Modal = ({ setIsModalOpen, children }) => {
  const { t } = useTranslation();
  return (
    <>
      {ReactDOM.createPortal(
        <div
          className="modalBox"
          // onClick={() => setIsModalOpen(false)}
        >
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <IconButton
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                height: "35px",
                width: "35px",
                zIndex: "9999",
              }}
              variant="contained"
              color="primary"
              onClick={() => setIsModalOpen(false)}
              title={t("close")}
            >
              <CloseTwoToneIcon style={{ color: "#3288cd" }} />
            </IconButton>
            {children}
          </div>
        </div>,
        document.getElementById("modal")
      )}
    </>
  );
};

const Transition = ({ isOpen, children, handleClose, setOpen }) => {
  return (
    <CSSTransition in={isOpen} timeout={250} classNames="modal" unmountOnExit>
      <Modal setIsModalOpen={setOpen}>
        <PromocodePurchaseModal />
      </Modal>
    </CSSTransition>
  );
};

const promocodePurchaseSteps = {
  PROMOCODE: "PROMOCODE",
  CARD: "CARD",
  FONDY: "FONDY",
  SUCCESS: "SUCCESS",
  PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
};
const promocodeTypes = {
  CONSULTATION: 1,
  SUBSCRIPTION: 0,
};

const PromocodePurchaseModal = () => {
  const { t } = useTranslation();
  const [promocode, setPromocode] = React.useState("");
  const [entity, setEntity] = React.useState(null);
  const [fondyUrl, setFondyUrl] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [infoLoading, setInfoLoading] = React.useState(false);
  const [step, setStep] = React.useState(promocodePurchaseSteps.PROMOCODE);
  const [loading, setLoading] = React.useState(false);
  const showInfoAfterFirstLoad = React.useRef(false);
  const userCards = useSelector(getUserCardsSelector);
  const dispatch = useDispatch();

  const getPromocodeInfo = React.useCallback(
    debounce(async (promocode) => {
      try {
        setInfoLoading(true);
        const response = await api.subscription.getSubscriptionPromocodeInfo(
          promocode
        );
        setEntity({
          ...response.data.entity,
          subscription: response.data.subscription,
        });
        showInfoAfterFirstLoad.current = true;
        setInfoLoading(false);
      } catch (error) {
        setEntity(null);
        showInfoAfterFirstLoad.current = true;
        setInfoLoading(false);
        setErrorMessage(error.message);
      }
    }, 800),
    []
  );

  React.useEffect(() => {
    if (promocode) {
      getPromocodeInfo(promocode);
    } else {
      showInfoAfterFirstLoad.current = false;
    }
  }, [promocode]);

  const onWindowMessage = (event) => {
    if (
      event.origin === "http://dobrodoc.com" ||
      event.origin.includes("web.dobrodoc") ||
      event.origin.includes("stage.patient")
    ) {
      setStep(promocodePurchaseSteps.SUCCESS);
    }
  };

  ///LISTEN TO MESSAGE FROM PAYMENT SYSTEM
  React.useEffect(() => {
    if (fondyUrl) {
      window.addEventListener("message", onWindowMessage);
    }
    return window.removeEventListener("message", onWindowMessage);
  }, [fondyUrl]);

  const formSubmit = async (event) => {
    event.preventDefault();
    if (!entity) return;

    //BOOK SUBSCRIPTION WITH PROMOCODE
    if (entity.type === promocodeTypes.SUBSCRIPTION) {
      setStep(promocodePurchaseSteps.CARD);
    }

    //SET DISCOUNT FOR ALLOWED SPECIALIZATIONS
    if (entity.type === promocodeTypes.CONSULTATION) {
      dispatch(
        setSpecializationsDiscount({
          idsList: entity.specialty.map((specialty) => specialty.id),
          discount: entity.discount,
          promocode,
        })
      );
      dispatch(openPromocodeModal(false));
    }
  };

  const handleInputChange = (event) => {
    setPromocode(event.target.value.trim());
  };

  const handleSuccessClick = () => {
    dispatch(openPromocodeModal(false));
  };

  const handleBookSubscription = async (cardId) => {
    const subscriptionId = entity.subscription.id;
    const body = { promocode: promocode };
    try {
      setLoading(true);
      const response = await api.subscription.bookSubscription(
        subscriptionId,
        cardId,
        body
      );
      setFondyUrl(response.entity.payment.paymentUrl);
      setStep(promocodePurchaseSteps.FONDY);
      setLoading(false);
    } catch (error) {
      setStep(promocodePurchaseSteps.PROMOCODE);
      setLoading(false);
    }
  };

  const getPromocodeTypeText = (entity) =>
    entity.type === 1
      ? `${t("promocodeName")} ${entity.discount}% ${t("forConsultation")}`
      : `${t("promocodeName")} ${entity.discount}% ${t("forSubscription")}`;

  const getConsultationInfoBoxStyle = React.useCallback(
    (specialtyCount = 0) => {
      let styles = {};
      if (specialtyCount === 1) {
        styles = {
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr",
        };
      }
      if (specialtyCount > 1 && specialtyCount <= 3) {
        styles = {
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "1fr",
        };
      }
      if (specialtyCount > 3 && specialtyCount <= 6) {
        styles = {
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
        };
      }
      if (specialtyCount > 6 && specialtyCount <= 9) {
        styles = {
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "repeat(3, 1fr)",
        };
      }
      return styles;
    },
    [entity]
  );

  const showPromocodeInfo =
    entity && showInfoAfterFirstLoad.current && !infoLoading && promocode;
  const showEmptyPromocodeInfo =
    !entity && showInfoAfterFirstLoad.current && !infoLoading && promocode;
  const isSubscription = entity && entity.type === promocodeTypes.SUBSCRIPTION;
  const isConsultation = entity && entity.type === promocodeTypes.CONSULTATION;

  return (
    <>
      {step === promocodePurchaseSteps.PROMOCODE && (
        <div className="enterPromocodeBox">
          <div className="enterPromocode">{t("activatePromocode")}</div>
          <div className="iconBox">
            <img
              height="50"
              width="50"
              className="promocodeIcon"
              src={promocodeIcon}
              alt="promocode-icon"
            />
          </div>
          <form className="promocodeForm" onSubmit={formSubmit}>
            <input
              type="text"
              value={promocode}
              placeholder={t("insertPromo")}
              className="promocodeInput"
              ref={(ref) => ref && ref.focus()}
              onChange={handleInputChange}
              maxLength="10"
            />
            {infoLoading && (
              <div className="loadingBox">
                <Spinner width={30} height={30} />
              </div>
            )}
            {showPromocodeInfo && isSubscription && (
              <div className="infoBox">
                <div className="promocodeType">
                  {getPromocodeTypeText(entity)}
                </div>
                <LoyaltyIcon
                  style={{
                    color: "#3288cd",
                    position: "absolute",
                    top: "50%",
                    height: "30px",
                    width: "30px",
                    transform: "translateY(-50%)",
                    left: "10px",
                  }}
                />
                {entity.subscription.shortDescription}
              </div>
            )}
            {showPromocodeInfo && isConsultation && (
              <div className="infoBoxConsultation">
                <div className="promocodeType">
                  {getPromocodeTypeText(entity)}
                </div>
                <LoyaltyIcon
                  style={{
                    color: "#3288cd",
                    position: "absolute",
                    top: "50%",
                    height: "30px",
                    width: "30px",
                    transform: "translateY(-50%)",
                    left: "10px",
                  }}
                />
                {entity.specialtyScope === 1 && (
                  <div
                    className="specialtyMapBox"
                    style={getConsultationInfoBoxStyle(entity.specialty.length)}
                  >
                    <Animationlist>
                      {entity.specialty.map((specialty) => (
                        <div key={specialty.id} className="specialtyBox">
                          <img
                            width="20"
                            height="20"
                            src={specialty.imageUrl}
                            alt="specialty-icon"
                          />
                          <div className="specialtyTitle">
                            {specialty.title}
                          </div>
                        </div>
                      ))}
                    </Animationlist>
                  </div>
                )}
                {entity.specialtyScope === 0 && (
                  <div className="allSpecialtyText">
                    {t("forAllConsultation")}
                  </div>
                )}
              </div>
            )}
            {showEmptyPromocodeInfo && (
              <div className="emptyResultBox">
                <ErrorOutlineIcon
                  style={{
                    color: "red",
                    marginRight: "10px",
                    height: "40px",
                    width: "40px",
                  }}
                />
                {errorMessage || t("promocodeNotExist")}
              </div>
            )}
            <Button
              className="btn"
              type="submit"
              text={t("activate")}
              buttonLoading={loading}
              disabled={!entity}
            />
          </form>
        </div>
      )}
      {step === promocodePurchaseSteps.CARD && (
        <div
          style={loading ? { display: "block" } : {}}
          className="cardContainer"
        >
          {loading && (
            <div className="loadingBox">
              <Spinner width={50} height={50} />
            </div>
          )}
          {!loading && (
            <>
              <div className="cardStepText">{t("selectMedicalCard")}</div>
              <Animationlist>
                {userCards.map((card) => {
                  return (
                    <div
                      onClick={() => handleBookSubscription(card.id)}
                      className="cardItem"
                    >
                      <img
                        width="50"
                        height="50"
                        src={card.photoUrl || patient_active}
                        alt="patient photo"
                      />
                      <div className="cardInfooContainer">
                        <div className="userName">{`${card.lastName} ${card.firstName}`}</div>
                        <div className="userRelation">
                          {card.relationShip.title}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Animationlist>
            </>
          )}
        </div>
      )}
      {step === promocodePurchaseSteps.FONDY && fondyUrl && (
        <div className="purchaseBox">
          <iframe
            src={fondyUrl}
            frameborder="0"
            className="purchaseSystem"
            title="fondySystem"
          ></iframe>
        </div>
      )}
      {step === promocodePurchaseSteps.SUCCESS && (
        <div className="bookingSuccessBox">
          <BookingSuccessComponent
            loading={loading}
            onClick={handleSuccessClick}
            text={t("subscriptionSuccess")}
          />
        </div>
      )}
    </>
  );
};
// https://pay.fondy.eu/api/checkout?token=5fb6d69539d3aea57db8602a6cf41863aab20009

export default Transition;
