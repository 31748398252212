import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { addNewAddress, getAddresses, getDistricts, getProvinces } from '../../../redux/addresses/addresses-operations';
import { addNotification } from '../../../redux/popup/popup-actions';
import { parserForm } from '../../../utils/parserForm';
import { SimpleForm } from '../../components/formComponents/SimpleForm/SimpleForm';
import SimpleInput from '../../components/formComponents/SimpleInput/SimpleInput';
import Button from '../../components/ui/Button/Button';
import './addressForm.scss'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import {IconButton} from '@material-ui/core'
import { getDistrictsSelector, getProvincesSelector } from '../../../redux/addresses/addresses-selectors';
import SimpleSelect from '../../components/formComponents/SimpleSelect/SimpleSelect';
import { disableBookingHome } from '../../../redux/consultations/consultations-actions';
import { useNavigate, useParams } from 'react-router-dom';

const AddressForm = ({
  addNewAddress,
  setShowForm,
  getAddresses,
  addNotification,
  mainComponent,
  input,
  setFormStep,
  getProvinces,
  getDistricts,
  provinces,
  districts,
  onClickLimited,
  isDisabledBookingAtHome = false,
  disableBookingHome,
}) => {
  const [province, setProvince] = useState(null)
  const [districtsLoading, setDistrictsLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()

  const formSubmit = async (e, resetForm) => {
    e.stopPropagation()
    e.preventDefault()
    const { ...fields } = parserForm(e)
    if (mainComponent && input?.onChange) {
      input.onChange({
        ...fields,
        provinceLabel: provinces.find(el => el.id == fields.province)?.title,
        districtLabel: districts.find(el => el.id == fields.district)?.title,
      })
      setFormStep(prev => prev + 1)
      return
    }
    setButtonLoading(true)
    await addNewAddress({
      address: {
        ...fields,
        city: provinces.find(el => el.id == fields.province).title,
      }
    })
    getAddresses()
    setButtonLoading(false)
    setShowForm(false)
  }

  useEffect(() => {
    getProvinces(params.id)
    return () => disableBookingHome(false)
  }, []);

  useEffect(() => {
    if (province) {
      setDistrictsLoading(true)
      getDistricts(province, params.id)
        .finally(() => setDistrictsLoading(false))
    }
  }, [province]);


  const handleInfoClick = () => {
    navigate(`/clinic/${params.id}/online`)
  }

  return (
    <div className={`addressFormContainer ${mainComponent ? 'main' : ''}`}>
      {!mainComponent && (
        <IconButton
          style={{ position: "absolute", right: "3px", top: "3px", color: "#3288cd" }}
          variant='contained'
          color='primary'
          onClick={() => setShowForm(prev => !prev)}
        >
          <CloseTwoToneIcon />
        </IconButton>
      )}
      <SimpleForm
        handleSubmit={formSubmit}
        autoComplete="off"
        novalidate
      >
        <div style={{ height: "50px"}}>
          <SimpleSelect
            name="province"
            autoComplete='off'
            placeholder={t("chooseCity")}
            setSelectedValue={setProvince}
            options={provinces.map(el => ({label: el.title, value: el.id,}))}
            // disabled={!isEnabled}
          />
          {/* <BasicPopover
            buttonText='Заказать онлайн'
            text={'sdcsdc sdcsdcs dcsdc sd csdc'}
            onClick={handleInfoClick}
          /> */}
          {/* <SimpleInput
            name="city"
            pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
            errorMessage={t('cityIsIncorrect')}
            // errorRequire={t('cityNameRequire')}
            placeholder={`${t("city")}*`}
            label={`${t("city")}*`}
            required
          /> */}
        </div>
        {mainComponent && isDisabledBookingAtHome && (
          <div style={{ height: "60px", margin: "0px"}}>
            <Button
              className='btn'
              secondaryRed
              text={t("disabledSpecialization") + ' ' + t("bookConsultationOnline")}
              withArrowRight
              onClick={onClickLimited}
            />
          </div>
        )}
        <div style={{ height: "50px" }}>
          <SimpleSelect
            name="district"
            autoComplete='off'
            placeholder={t("chooseDistrict")}
            options={districts.map(el => ({label: el.title, value: el.id,}))}
            disabled={isDisabledBookingAtHome || !province || districtsLoading}
            loading={districtsLoading}
          />
        </div>
        <div style={{ height: "45px" }}>
          <SimpleInput
            name="thoroughfare"
            autoComplete="off"
            // pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ | -]{1,}$"
            // errorMessage={t('thoroughfareIsIncorrect')}
            // errorRequire={t('thoroughfareRequire')}
            placeholder={`${t("street")}`}
            label={`${t("street")}`}
            required
            disabled={isDisabledBookingAtHome}
          />
        </div>
        <div className='inlineContainer'>
          <div style={{ width: "49%", height: "45px" }}>
            <SimpleInput
              name="houseNumber"
              pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ0-9_-]{1,16}$"
              errorMessage={t('buildingIsIncorrect')}
              // errorRequire={t('buildingIsRequire')}
              label={`${t("building")}`}
              placeholder={`${t("building")}`}
              disabled={isDisabledBookingAtHome}
              required
            />
          </div>
          <div style={{ width: "49%", height: "45px" }}>
            <SimpleInput
              name="flatNumber"
              // pattern="^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ0-9_-]{1,16}$"
              // errorMessage={t('appartmentIsIncorrect')}
              placeholder={`${t("apartment")}`}
              label={`${t("apartment")}`}
              // required
              disabled={isDisabledBookingAtHome}
            />
          </div>
        </div>
        <div className='inlineContainer'>
          <div style={{ width: "49%", height: "45px" }}>
            <SimpleInput
              className='addressInput'
              name='entrance'
              component='input'
              placeholder={t('EntranceNumber')}
              label={`${t("EntranceNumber")}`}
              autoComplete='off'
              disabled={isDisabledBookingAtHome}
            />
          </div>
          <div style={{ width: "49%", height: "45px" }}>
            <SimpleInput
              className='addressInput'
              name='floor'
              // pattern="^[0-9]+$"
              // errorMessage={t('stageIsIncorrect')}
              placeholder={`${t("Stage")}`}
              label={`${t("Stage")}`}
              // required
              disabled={isDisabledBookingAtHome}
            />
          </div>
        </div>
        <div style={{ height: "80px" }}>
          <SimpleInput
            className='addressInput'
            name='comment'
            placeholder={`${t("codeOrNote")}`}
            label={`${t("codeOrNote")}`}
            rows={4}
            disabled={isDisabledBookingAtHome}
          />
        </div>
        {!mainComponent && (
          <Button
            text={t("AddAddress")}
            type='submit'
            buttonLoading={buttonLoading}
            className='btn'
          />
        )}
        {mainComponent && (
          <div className="outerButtonBox">
            <Button
              text={t("Next")}
              type='submit'
              // onClick={formSubmit}
              className='btn'
              disabled={isDisabledBookingAtHome}
            />
          </div>
        )}
      </SimpleForm>
    </div>
  )
}

const mapStateToProps = (state) => ({
  provinces: getProvincesSelector(state),
  districts: getDistrictsSelector(state),
})
const mapDispatchToProps = {
  addNewAddress,
  getAddresses,
  addNotification,
  getProvinces,
  getDistricts,
  disableBookingHome,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm)
