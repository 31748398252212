import React, { useRef } from 'react';
import './chat1.scss'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import mesTypes from '../../../utils/chatTypes'
import { connect } from 'react-redux';
import {
  uploadChatFile,
  uploadSupportFile
} from '../../../redux/chat/chat-operations';
import { sendMessage, resetSearch, sendAnamnezMessage } from '../../../redux/chat/chat-actions';
import { bytesToSize, getCurrentDate, getFileType } from '../../../utils/helpers';
import info from '../../../utils/info';
import 'react-circular-progressbar/dist/styles.css';
import deleteIcon from '../../../style/icons/Close/cancel.svg'
import {useDropzone} from 'react-dropzone'
import ModalWindow from '../../components/ModalWindow/ModalWindow'
import { IconButton } from '@material-ui/core';
import ScrollableFeed from 'react-scrollable-feed'
import { sortByDate } from '../../../utils/sort';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import activeDocIcon from "../../../style/icons/Doc/doc-blue.svg";
import OrdinaryMessage from './MessagesTypes/OrdinaryMessage';
import AudioMessage from './MessagesTypes/AudioMessage';
import VideoMessage from './MessagesTypes/VideoMessage';
import ImageMessage from './MessagesTypes/ImageMessage';
import FileMessage from './MessagesTypes/FileMessage';
import ChatInputPanel from './ChatInputPanel/ChatInputPanel';
import moment from 'moment';
import TitleComponent from '../TitleComponent/TitleComponent';
import support from '../../../style/icons/Sider/support.svg'
import DateSearch from '../DateSearch/DateSearch';
import { disableAnamnesis } from '../../../redux/consultations/consultations-operations';
import { getAnamnesisList } from '../../../redux/consultations/consultations-selectors';
import { getChatMessages } from '../../../redux/chat/chat-selectors';
import { Detector } from "react-detect-offline";
import Carousel from '../ui/Carousel/Carousel'
import { InternetStateComponent } from "./ConnectionState/ConnectionState"
import { getChatHistory } from '../../../redux/socket/socketActions';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { useSearchParams } from 'react-router-dom';

const validForDateEnd = moment("28/10/2021", "DD/MM/YYYY").subtract(1, "day")

export const validDateStart = function( current ){
    return current.isAfter( validForDateEnd );
};

const Chat1 = ({
  messages,
  loading,
  uploadChatFile,
  sendMessage,
  uploadSupportFile,
  supportMode,
  consultationInfo,
  sidebarMode,
  chatOpenedWithVideo = true,
  setChatOpenedWithVideo,
  audioAllowed,
  currentPage,
  nextPageLoading,
  isTheEndPage,
  messagesBySearch,
  resetSearch,
  anamnesis,
  sendAnamnezMessage,
  disableAnamnesis,
  getChatHistory,
}) => {
  const [statusUploadFile, setStatusUploadFile] = useState(0);
  const [messagesByDate, setMessagesByDate] = useState({});
  const [filterDates, setFilterDates] = useState({
    startDate: "",
    endDate: "",
  })
  const [imageSrc, setImageSrc] = useState(null)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const { t } = useTranslation()
  const [searchParams] = useSearchParams();
  const chatInputRef = useRef()
  const fileInputRef = useRef()
  const mediaInputRef = useRef()
  const absoluteDateRef = useRef()
  const chatAreaRef = useRef()
  const dateArray = useRef([])
  const scrollAreaRef = useRef(null)
  const prevScrollPosition = useRef(null)
  const scrollOnNextPage = useRef(false)
  const appointmentId = searchParams.get("id");

  const getHistory = (page = 0) => {
    getChatHistory({
      page: page,
      userId: info.getUserId(),
      startDate: filterDates.startDate,
      endDate: filterDates.endDate,
      isSupport: !!supportMode,
      appointmentId: appointmentId,
      typeRoom: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
    })
  }

  useEffect(() => { getHistory() }, [supportMode])
  useUpdateEffect(() => {getHistory()}, [filterDates])

  useEffect(() => {
    if (anamnesis.length > 0) {
      const {chatText, id} = anamnesis[0]
      sendAnamnezMessage({chatText, id, consultId: appointmentId})
    }
  }, [anamnesis])

  useEffect(() => {
    if (messagesBySearch.length > 0) {
      setMessagesByDate(sortByDate(messagesBySearch))
    } else {
      setMessagesByDate(sortByDate(messages))
    }
  }, [messages, messagesBySearch])

  const handleSendMessage = (value, type) => {
    const messageBody = {
      messageType: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
      type: type || mesTypes.MESSAGE,
      message: value,
      dateSent: getCurrentDate(),
      isSentByUser: true,
      customParameters: {
        some: "param",
      },
      appointmentId: appointmentId || "",
    }
    sendMessage({ messageBody, isSupport: supportMode })

    handleScrollBottom()
  }
  
  ////SEND AUDIO MESSAGE
  const handleSendAudioMessage = (file, duration) => {
    const socketBodyWithoutMessage = {
      messageType: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
      type: "voice",
      dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      isSentByUser: true,
      appointmentId: appointmentId || "",
      customParameters: {
        some: "sam some",
        audioDuration: duration || '',
      },
    }
    const requestBody = {
      appointmentId: appointmentId,
      file: file,
      setUploadStatus: setStatusUploadFile,
    }

    const supportRequestBody = {
      userId: info.getUserId(),
      file: file,
      setUploadStatus: setStatusUploadFile,
    }
    if (supportMode) {
      uploadSupportFile(supportRequestBody, socketBodyWithoutMessage)
    } else {
      uploadChatFile(requestBody, socketBodyWithoutMessage)
    }
  }


  /////SEND FILE (FILE OR MEDIA)
  const handleAttach = (e = null, file = null) => {
    const inputFile = e?.target?.files[0]
    
    const requestBody = {
      appointmentId: appointmentId,
      file: inputFile || file,
      setUploadStatus: setStatusUploadFile,
    }

    const supportRequestBody = {
      userId: info.getUserId(),
      file: inputFile || file,
      setUploadStatus: setStatusUploadFile,
    }

    const socketBodyWithoutMessage = {
      messageType: supportMode
        ? "patientToOperator"
        : "patientToDoctor",
      type: getFileType(inputFile?.type || file?.type),
      dateSent: getCurrentDate(),
      isSentByUser: true,
      appointmentId: appointmentId || "",
      customParameters: {
        file_name: inputFile?.name || file?.name,
        size: bytesToSize(inputFile?.size || file?.size),
      },
    }
    if (supportMode) {
      uploadSupportFile(supportRequestBody, socketBodyWithoutMessage)
      if (e) {
        handleClearInput(e.target)
      }
      return
    }
    uploadChatFile(requestBody, socketBodyWithoutMessage)
    if (e) {
      handleClearInput(e.target)
    }
  }

  //CLEARING INPUT AFTER SENDING FILE
  const handleClearInput = (input) => {
    input.value = null
  }

  //CHAT DROP ZONE
  const onDrop = useCallback(acceptedFiles => {
    handleAttach(null, acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  /////OPEN MODAL
  const handleOpenImage = (e) => {
    setImageSrc(e.target.src)
  }

  ////CLOSE MODAL
  const handleCloseModal = () => {
    setImageSrc(null)
  }

  ////DISABLING ANAMNESIS QUESTIONS
  const handleDisableAnamnez = () => {
    disableAnamnesis(consultationInfo?.id)
  }

  ///// RENDERING DIFFERENT TYPES OF MESSAGES
  const _renderMessage = (type, id, props) => {
    switch (type) {
      case mesTypes.MESSAGE:
        return <OrdinaryMessage key={id} {...props} />
      case mesTypes.VOICE:
        return <AudioMessage key={id} {...props} />
      case mesTypes.IMAGE:
        return <ImageMessage key={id} handleOpenImage={handleOpenImage} {...props} />
      case mesTypes.FILE:
        return <FileMessage key={id} {...props} />
      case mesTypes.VIDEO:
        return <VideoMessage key={id} {...props} />
      case mesTypes.CHAT_BOT:
        return <OrdinaryMessage handleDisableAnamnez={handleDisableAnamnez} key={id} {...props} />
      case mesTypes.CANCELATION:
        return <OrdinaryMessage key={id} {...props} />
      case mesTypes.FINISHED:
        return <OrdinaryMessage key={id} {...props} />
      default:
        return
    }
  }
  ///AUTO RESIZING FOR CHAT INPUT
  useEffect(() => {
    if (chatInputRef.current) {
      document.querySelectorAll('[data-autoresize]').forEach(function (element) {
        element.style.boxSizing = 'border-box';
        var offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', function (event) {
          event.target.style.height = 'auto';
          event.target.style.height = event.target.scrollHeight + offset + 'px';
        });
        element.removeAttribute('data-autoresize');
      });
    }
  }, [chatInputRef])

  ///scroll chat to bottom
  const handleScrollBottom = (scrollTo = 9999999) => {
    const ref = document.querySelector(".scrollArea")
    ref.scrollTo({
      top: scrollTo
    })
  }

  ///Calculating scroll redirect after fetching next page
  const calculateScrollPosition = () => {
    const { scrollHeight, clientHeight } = scrollAreaRef.current.wrapperRef.current
    const currentPosition = currentPage === 0
      ? 0
      : scrollHeight - clientHeight
    const delta = currentPosition - prevScrollPosition.current
    const scrollToPosition = delta < 0 ? prevScrollPosition.current : delta
    return scrollToPosition
  }

  ///SCROLL AFTER NEXT PAGE
  useEffect(() => {
    if (currentPage > 0 && scrollOnNextPage.current) {
      handleScrollBottom(calculateScrollPosition())
      setTimeout(() => scrollOnNextPage.current = false, 100)
    }
  }, [messagesByDate, currentPage])
  

  ////SCROLL EVENT TO dynamically CALCULATE DATE IN CHAT
  const handleScroll = (isAtBottom) => {
    if (!isAtBottom) {
      setIsAtBottom(false)
    }
    if (isAtBottom) {
      setIsAtBottom(true)
    }

    const { scrollTop, clientHeight, scrollHeight } = scrollAreaRef.current.wrapperRef.current
    const isTheEnd = scrollTop === 0
    if (isTheEnd && !nextPageLoading && !isTheEndPage) {
      prevScrollPosition.current = scrollHeight - clientHeight
      scrollOnNextPage.current = true
      getHistory(currentPage + 1)
    }

    const top = scrollAreaRef.current.wrapperRef.current.scrollTop;
    for (let i = 0; i < dateArray.current.length - 1; i++) {
      if (top <= 5) {
        absoluteDateRef.current.textContent = ''
        return
      }
      if (dateArray.current[i]?.offsetTop < top && dateArray.current[i + 1]?.offsetTop > top) {
        absoluteDateRef.current.textContent = dateArray.current[i].textContent;
        return
      }
    }
  }

  ///Searching By Date
  const handleSearchByDate = ({ startDate, endDate }) => {
    setFilterDates({startDate, endDate})
    // getHistory(0, startDate, endDate)
  }

  const handleResetSearch = () => {
    setFilterDates({startDate: "", endDate: ""})
    // getHistory(0)
  }


  return (
    <div className={`pageContainer ${sidebarMode ? 'small' : ''} ${!chatOpenedWithVideo ? 'dnone' : ''}`}>
      {!sidebarMode && (consultationInfo || supportMode) && (
        <div className="pageHeaderBox">
          <TitleComponent
            loading={nextPageLoading}
            fullWidthIcon={supportMode ? false : true}
            icon={supportMode ? support : consultationInfo?.doctor?.employee?.photoUrl || activeDocIcon}
            title={supportMode ? t("Support") : null}
            titlePrimary={!supportMode && consultationInfo?.doctor?.employee?.fullName}
            titleSecondary={!supportMode && consultationInfo?.specialty?.title}
          />
          <DateSearch
            isValidDate={validDateStart}
            handleReset={handleResetSearch}
            handleSearch={handleSearchByDate}
          />
        </div>
      )}
      {!sidebarMode && !consultationInfo && !supportMode && (
        <div className="pageHeaderBox loading">
          <Carousel />
        </div>
      )}
      <div
        className={`chatContainer ${sidebarMode
          ? 'small' : ''} ${!chatOpenedWithVideo ? 'dnone' : ''}`}
        {...getRootProps()}
        onClick={(e) => { }}
        onKeyDown={(e) => { }}
        onKeyPress={(e) => { }}
        onKeyUp={(e) => { }}
      >
        {sidebarMode && !supportMode && (
          <div className='closePanel'>
            <img
              onClick={() => setChatOpenedWithVideo(false)}
              src={deleteIcon}
              alt="close-panel"
              title={'Закрыть чат'}
            />
          </div>
        )}
        {isDragActive && (
          <div className='dragNdrop'>
            <div className='dropFileContainer'>
              <div className='dropFileText'>Отправить Файл...</div>
            </div>
          </div>
        )}
        <div
          ref={chatAreaRef}
          className={`chatArea ${sidebarMode ? 'small' : ''}`}
        >
          <div ref={absoluteDateRef} className='absoluteDate isVisible'></div>
          {!isAtBottom && (
            <IconButton
              style={{ position: "absolute", bottom: "10px", left: "30px", zIndex: "99999" }}
              variant='contained'
              color='primary'
              onClick={() => handleScrollBottom()}
              title={t("back")}
            >
              <ArrowDownwardSharpIcon style={{ width: "30px" }} />
            </IconButton>
          )}
          <input
            {...getInputProps()}
            style={{ border: "none", outline: 'none', width: '0px', height: "0px" }}
            onKeyDown={(e) => { }}
            onClick={(e) => { }}
            onKeyDown={(e) => { }}
            onKeyUp={(e) => { }}
          />
          <LoadingComponent loading={loading}>
            {
              <ScrollableFeed
                onScroll={handleScroll}
                className='scrollArea'
                ref={scrollAreaRef}
              >
                {Object.keys(messagesByDate).map((date, i) => {
                  return (<>
                    <div key={i} id={i} ref={(ref) => dateArray.current[i] = ref} className='date'>{date}</div>
                    {messagesByDate[date].map(mes => {
                      return _renderMessage(mes.type, mes._id, { ...mes })
                    })}
                  </>)
                })}
              </ScrollableFeed>
            }
          </LoadingComponent>
        </div>
        <Detector
          render={({ online }) => {
            if (online) {
              return (
                <ChatInputPanel
                  supportMode={supportMode}
                  audioAllowed={audioAllowed}
                  statusUploadFile={statusUploadFile}
                  chatInputRef={chatInputRef}
                  handleAttach={handleAttach}
                  fileInputRef={fileInputRef}
                  mediaInputRef={mediaInputRef}
                  handleSendMessage={handleSendMessage}
                  handleSendAudioMessage={handleSendAudioMessage}
                />
              )
            } else {
              return (
                <div className='textareaChatContainer'>
                  <InternetStateComponent t={t} />
                </div>
              )
            }
          }}
        />
        
        <ModalWindow handleCloseModal={handleCloseModal} open={imageSrc} imageFormat>
          <img className='chatPicture' src={imageSrc} alt="chatPicture" />
        </ModalWindow>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.chat.loading,
  messages: getChatMessages(state),
  chatMode: state.chat.chatMode,
  error: state.chat.error,
  audioAllowed: state.chat.audioAllowed,
  currentPage: state.chat.currentPage,
  isTheEndPage: state.chat.isTheEndPage,
  totalPageCount: state.chat.totalPageCount,
  messagesBySearch: state.chat.messagesBySearch,
  anamnesis: getAnamnesisList(state),
})

const mapDispatchToProps = {
  uploadChatFile,
  sendMessage,
  uploadSupportFile,
  resetSearch,
  sendAnamnezMessage,
  disableAnamnesis,
  getChatHistory,
}


export default connect(mapStateToProps, mapDispatchToProps)(Chat1);
