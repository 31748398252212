import React, { useState, useEffect, useRef } from "react";
import ScrollArea from "react-scrollbar";
import cm from "classnames";
import shevron from "../../../../style/icons/Shevron/shevron-down.svg";
import close from "../../../../style/icons/Shevron/close.svg";
import useUpdateEffect from '../../../../hooks/useUpdateEffect'
import './simpleSelect.scss';
import Spinner from "../../ui/Spinner/Spinner";

const SimpleSelect = ({
  options = [],
  defaultValue,
  placeholder,
  setSelectedValue,
  required,
  loading,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(null)
  const inputRef = useRef(null);
  const mainRef = useRef()
  const optionRef = useRef(null)

  const handleReset = () => {
    setIsActive(false);
    inputRef.current.value = '';
  }

  useEffect(()=> {
    if (defaultValue) {
      inputRef.current.value = defaultValue.value;
    }
  }, [defaultValue]);

  const clearSelected = ()=> {
    inputRef.current.value = '';
    setIsActive(true);
    setSelected(null)
  }

  const toggleState = () => {
    setIsActive(!isActive)
  }
  const handleChange = (item) => {
    inputRef.current.value = item.value;
    setSelected(item.label)
    if(setSelectedValue) setSelectedValue(item.value)
    setIsActive(false);
  };

  const handleFocus = () => {
    if (rest.disabled) {
      return
    }
    openList()
  }

  const openList = () => {
    if (rest.disabled) {
      return
    }
    setIsActive(!isActive)
  };

  const toggleActive = isActive =>  !isActive

  return (
    <div id={`id${rest.name}`} ref={mainRef} onClick={(e) => {
      if (e.target !== e.currentTarget) {
        openList(e)
      }
    }} className="formField">
      <div className="fieldSimpleSelect">
        {loading && (
          <div className="spinnerBox">
            <Spinner width={30} height={30}/>
          </div>
        )}
        {inputRef.current?.value && <div className="placeholder">{placeholder}</div>}
        <div onClick={openList} >
        <input
          className={`simpleInput ${isActive ? 'isActive' : ''}`}
          type="input"
          style={{pointerEvents: "none"}}
          placeholder={required ? `${placeholder}*` :placeholder}
          // onChange={handleChange}
          onReset={handleReset}
          onFocus={handleFocus}
          ref={inputRef}
          required={required}
          {...rest}
          // disabled
        />
        {!defaultValue && selected && <div className="value">
          {selected}
          </div>}
          {defaultValue && !selected && <div className='value'>
            {defaultValue.label}
          </div>}
          {defaultValue && selected && <div className='value'>
            {selected}
          </div>}
        </div>
        {selected && (
          <img
            className="circle"
            src={close}
            alt=""
            onClick={clearSelected}
          />
        )}
        {!selected && !loading && (
          <img
            className={`shevron ${isActive ? "active" : ""}`}
            src={shevron}
            alt=""
            onClick={(e) => {
              openList()
              toggleActive(e)
            }}
          />
        )}
        <div className={"wrapperOptions"}>
          {isActive && (
            <ScrollArea speed={0.1} className={"area"} horizontal={false}>
              <div className={"options"}>
                {options.map((option, index) => (
                  <div
                    ref={optionRef}
                    key={option.value}
                    className={cm("option", {
                      active: option.value == inputRef.current.value,
                    })}
                    onClick={() => handleChange(option)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </ScrollArea>
          )}
        </div>
      </div>
    </div>
  );
};
export default SimpleSelect;