import React from 'react';
import './dateSearch.scss';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from '../../components/DatePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IconButton} from '@material-ui/core';
import { useStyles } from './styles';
import moment from 'moment';


const DateSearch = React.memo(({
  formValues,
  reset,
  handleSearch,
  handleReset,
  isValidDate,
}) => {
  const [dateOpen, setOpenDate] = React.useState(false)
  const { t } = useTranslation()
  const classes = useStyles()

  const handleResetForm = () => {
    reset()
    setOpenDate(false)
    handleReset()
  }

  const handleSubmit = () => {
    const startDate = moment(formValues.startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    const endDateFormat = moment(formValues.endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    const endDate = endDateFormat !== 'Invalid date' ? endDateFormat : "";
    handleSearch({ startDate, endDate })
  }

  return (
    <div className={`dateSearchContainer ${dateOpen ? "bigger" : ""}`}>
      {!dateOpen && (
        <div onClick={() => setOpenDate(true)} className="searchTitleBox">
          <SearchIcon className={classes.searchIcon} />
          <div className="searchTitle">{t("searchByDate")}</div>
        </div>
      )}
      {dateOpen && (
        <div className="dateContainer">
          <Field
            component={DatePicker}
            isValidDate={isValidDate}
            defaultValue=""
            name="startDate"
            // placeholder={t("birthDate")}
            required
            validDate={{ number: 0, type: "year", period: "isBefore" }}
          />
          <div className="line"></div>
          <Field
            component={DatePicker}
            defaultValue=""
            isValidDate={isValidDate}
            name="endDate"
            // placeholder={t("birthDate")}
            required
            validDate={{ number: 0, type: "year", period: "isBefore" }}
            disabled={!formValues?.startDate}
          />
          <IconButton
            classes={{ root: classes.button, disabled: classes.buttonDisabled }}
            disabled={!formValues?.startDate}
            onClick={handleSubmit}
          >
            <SearchIcon
              style={formValues?.startDate ? { color: "white" } : {}}
            />
          </IconButton>
          <CloseIcon
            className={classes.closeIcon}
            onClick={handleResetForm}
          />
        </div>
      )}
    </div>
  );
})

const mapStateToProps = (state) => ({
  formValues: getFormValues("dateForm")(state),
})

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({ form: "dateForm", enableReinitialize: true })
);

export default enhance(DateSearch);
// export default compose(reduxForm({form: 'dateForm', enableReinitialize: true}))(DateSearch);
