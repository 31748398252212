import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import store from './redux/store';
import './i18n';
import './index.scss'
import NotificationProvider from './views/components/NotificationProvider/NotificationProvider';
import ErrorBoundary from './views/components/ErrorBoundary/ErrorBoundary';


const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>
);
